export default () => {
  return {
    prop: 'description',
    title: '采购申请说明',
    metas: [
      {
        type: 'textarea',
        label: '申请原因描述',
        prop: 'reason',
        value: ''
      },
      {
        hidden: true,
        type: 'files',
        label: '选择上传附件',
        prop: 'files',
        value: ''
      }
    ]
  };
};

import setting from '@/setting';

export default () => {
  return [
    {
      title: '科室/项目经理',
      detail: '',
      users: [],
      max: 5,
      status: ''
    },
    {
      title: '归口业务经理',
      detail: '',
      users: [],
      max: 5,
      status: ''
    },
    {
      title: '分管业务总监',
      detail: '',
      users: [],
      max: 5,
      status: ''
    },
    {
      title: '预算控制',
      detail: '1人审批',
      users: [setting.auditers.budget],
      max: 1,
      status: '',
      disabled: true
    },
    {
      title: '财务总监',
      detail: '1人审批',
      users: [setting.auditers.cfo],
      max: 1,
      status: '',
      disabled: true
    },
    {
      title: '总经理',
      detail: '',
      users: [],
      max: 1,
      status: ''
    }
  ];
};

export default () => {
  return [
    { label: '物业管理费', value: '物业管理费' },
    { label: '开发成本', value: '开发成本' },
    { label: '开发间接费用', value: '开发间接费用' },
    { label: 'sgmw 奖福基金', value: 'sgmw 奖福基金' },
    { label: '在建工程', value: '在建工程' },
    { label: '固定资产', value: '固定资产' },
    { label: '保险费', value: '保险费' },
    { label: '广告费', value: '广告费' },
    { label: '车辆费用', value: '车辆费用' },
    { label: '物料消耗', value: '物料消耗' },
    { label: '递延费用摊销', value: '递延费用摊销' },
    { label: '差旅费', value: '差旅费' },
    { label: '接待费', value: '接待费' },
    { label: '运输费', value: '运输费' },
    { label: '邮寄费', value: '邮寄费' },
    { label: '汽油费', value: '汽油费' },
    { label: '培训费', value: '培训费' },
    { label: '制作费', value: '制作费' },
    { label: '咨询费', value: '咨询费' },
    { label: '销售奖励', value: '销售奖励' },
    { label: '租赁费', value: '租赁费' },
    { label: '劳务费', value: '劳务费' },
    { label: '会议费', value: '会议费' },
    { label: '其他-水样检测费', value: '其他-水样检测费' },
    { label: '办公费', value: '办公费' },
    { label: '安全管理费', value: '安全管理费' },
    { label: '装卸费', value: '装卸费' },
    { label: '职工福利费', value: '职工福利费' },
    { label: '文宣费', value: '文宣费' },
    { label: '修理费', value: '修理费' },
    { label: '团队活动费', value: '团队活动费' },
    { label: '产品研发费', value: '产品研发费' },
    { label: '试验费', value: '试验费' },
    { label: '年审费', value: '年审费' },
    { label: '印刷费', value: '印刷费' },
    { label: '工会经费', value: '工会经费' },
    { label: '工作餐', value: '工作餐' },
    { label: '水费', value: '水费' },
    { label: '电费', value: '电费' },
    { label: '团队建设费', value: '团队建设费' },
    { label: '销售中介', value: '销售中介' },
    { label: '报刊费', value: '报刊费' },
    { label: '排污费', value: '排污费' },
    { label: '慰问费', value: '慰问费' },
    { label: '绿化费', value: '绿化费' },
    { label: '保洁费', value: '保洁费' },
    { label: '人员招聘费', value: '人员招聘费' },
    { label: '服务费', value: '服务费' },
    { label: '手续费', value: '手续费' },
    { label: '业务招待费', value: '业务招待费' },
    { label: '其他', value: '其他' }
  ];
};

export default () => {
  return {
    prop: 'title',
    title: '采购单',
    metas: [
      {
        type: 'text',
        label: '标题',
        prop: 'title',
        vaue: '',
        required: true
      },
      {
        type: 'select',
        label: '子公司',
        prop: 'subcompary',
        vaue: '',
        options: [
          {
            label: '柳州益菱汽车投资发展有限公司青岛分公司',
            value: '柳州益菱汽车投资发展有限公司青岛分公司'
          },
          {
            label: '柳州益菱汽车投资发展有限公司重庆商贸分公司',
            value: '柳州益菱汽车投资发展有限公司重庆商贸分公司'
          },
          {
            label: '柳州智出行融资租赁有限公司',
            value: '柳州智出行融资租赁有限公司'
          },
          {
            label: '广西宝菱康科技有限公司',
            value: '广西宝菱康科技有限公司'
          },
          {
            label: '广西宝菱康医疗器械有限公司',
            value: '广西宝菱康医疗器械有限公司'
          }
        ]
      }
    ]
  };
};

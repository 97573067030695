export default () => {
  return [
    { label: '柳州基地（燃气站）', value: '柳州基地（燃气站）' },
    { label: '上海宝骏前瞻设计中心', value: '上海宝骏前瞻设计中心' },
    { label: '外租宿舍', value: '外租宿舍' },
    { label: '宝菱康医疗-熔喷布', value: '宝菱康医疗-熔喷布' },
    { label: '青岛汽车租赁&营销服务', value: '青岛汽车租赁&营销服务' },
    { label: '重庆汽车租赁&营销服务', value: '重庆汽车租赁&营销服务' },
    { label: '宝菱康科技-公司办', value: '宝菱康科技-公司办' },
    { label: '宝菱康医疗-公司办', value: '宝菱康医疗-公司办' },
    { label: '宝菱康医疗-设备部', value: '宝菱康医疗-设备部' },
    { label: '工业旅游', value: '工业旅游' },
    { label: '智出行公司', value: '智出行公司' },
    { label: '柳东宝骏城项目', value: '柳东宝骏城项目' },
    { label: '综合管理科', value: '综合管理科' },
    { label: '财务科', value: '财务科' },
    { label: '柳州基地(资源综合利用)', value: '柳州基地(资源综合利用)' },
    { label: '柳州基地(餐饮)', value: '柳州基地(餐饮)' },
    { label: '宝骏家园', value: '宝骏家园' },
    { label: '宝骏家园二期', value: '宝骏家园二期' },
    { label: '直饮水', value: '直饮水' },
    { label: '工程&众筹房运营科', value: '工程&众筹房运营科' },
    { label: '五菱家园', value: '五菱家园' },
    { label: '桃源居', value: '桃源居' },
    { label: '汽车租赁&营销服务', value: '汽车租赁&营销服务' },
    { label: '孵化器', value: '孵化器' },
    { label: '重庆开卷落料线', value: '重庆开卷落料线' },
    { label: '重庆资源再生中心', value: '重庆资源再生中心' },
    { label: '重庆综合管理', value: '重庆综合管理' },
    { label: '重庆直饮水', value: '重庆直饮水' },
    { label: '重庆标准厂房', value: '重庆标准厂房' },
    { label: '青岛综合管理', value: '青岛综合管理' },
    { label: '青岛再生资源', value: '青岛再生资源' },
    { label: '青岛直饮水', value: '青岛直饮水' }
  ];
};

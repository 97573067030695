export default (closable, key) => {
  return {
    prop: 'content',
    title: '采购内容',
    closable,
    key,
    metas: [
      {
        type: 'text',
        label: '序号',
        prop: 'index',
        value: '',
        required: true
      },
      {
        type: 'text',
        label: '名称',
        prop: 'name',
        value: '',
        required: true
      },
      {
        type: 'textarea',
        label: '详述及技术技能',
        prop: 'description',
        value: ''
      },
      {
        type: 'text',
        label: '单位',
        prop: 'unit',
        value: '',
        required: true
      },
      {
        type: 'number',
        label: '数量',
        prop: 'number',
        value: '',
        required: true
      },
      {
        type: 'number',
        label: '单价(元)(估价)(不含增值税)',
        prop: 'price',
        value: '',
        required: true,
        precision: 2
      },
      {
        type: 'number',
        label: '总价格(元)(估价)(不含增值税)',
        prop: 'amount',
        value: '',
        disabled: true,
        precision: 2
      },
      {
        type: 'number',
        label: '实际单价(元)(估价)(不含增值税)',
        prop: 'actualPrice',
        value: '',
        disabled: true,
        precision: 2
      },
      {
        type: 'number',
        label: '实际总价格(元)(估价)(不含增值税)',
        prop: 'actualAmount',
        value: '',
        disabled: true,
        precision: 2
      }
    ]
  };
};

export default () => {
  return {
    prop: 'total',
    title: '汇总',
    metas: [
      {
        type: 'number',
        label: '总计(元)(估价)(不含增值税)',
        prop: 'total',
        value: '',
        disabled: true
      }
    ]
  };
};

export default () => {
  return {
    prop: 'apply',
    title: '采购申请',
    metas: [
      {
        type: 'date',
        label: '申请日期',
        prop: 'applyDate',
        value: '',
        required: true
      },
      {
        type: 'date',
        label: '要求进货日期',
        prop: 'requireDate',
        value: '',
        required: true
      }
    ]
  };
};

export default () => {
  return [
    { label: '综合管理科', value: '综合管理科' },
    { label: '新业务规划发展科', value: '新业务规划发展科' },
    { label: '员工服务科', value: '员工服务科' },
    { label: '工业服务区域', value: '工业服务区域' },
    { label: '工程建设科', value: '工程建设科' },
    { label: '新能源生态链服务科', value: '新能源生态链服务科' },
    { label: '财务科', value: '财务科' },
    { label: '柳州益菱本部', value: '柳州益菱本部' },
    { label: '重庆商贸分公司', value: '重庆商贸分公司' },
    { label: '青岛分公司', value: '青岛分公司' }
  ];
};

import titleItem from './title';
import applyItem from './apply';
import basicItem from './basic';
import contentItem from './content';
import totalItem from './total';
import descriptionItem from './description';
import stepsItem from './steps';

export default {
  titleItem,
  applyItem,
  basicItem,
  contentItem,
  totalItem,
  descriptionItem,
  stepsItem
};

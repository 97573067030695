<template>
  <a-layout>
    <a-page-header title="创建采购单" @back="$router.back()" />
    <a-form-model
      ref="form"
      :model="model"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 8 }"
    >
      <section v-for="(values, name, index) in descriptor" :key="name">
        <div v-if="index != 3">
          <div v-for="(item, j) in values" :key="j">
            <a-divider orientation="left">{{ item.title }}</a-divider>
            <app-form-item :index="j" :item="item" :model="model" />
          </div>
        </div>
        <div v-else>
          <a-divider orientation="left">采购内容</a-divider>
          <a-tabs type="editable-card" v-model="active" @edit="onEditTabs">
            <a-tab-pane
              v-for="(item, j) in values"
              :key="j"
              :tab="`${item.title} ${j + 1}`"
              :closable="item.closable"
            >
              <app-form-item :index="j" :item="item" :model="model" />
            </a-tab-pane>
          </a-tabs>
        </div>
      </section>
      <a-divider />
      <a-form-model-item :wrapper-col="{ offset: 6, span: 18 }">
        <app-steps :steps="steps" @change="onChangeStep" />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ offset: 6, span: 18 }">
        <a-button
          size="large"
          icon="arrow-left"
          style="margin-right: 16px;"
          @click="$router.back()"
        >
          返回
        </a-button>
        <a-button
          size="large"
          icon="check"
          type="primary"
          :loading="loading"
          @click="onSubmit"
        >
          {{ submitText }}
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-layout>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import descriptors from '@/descriptors';

export default {
  name: 'Form',
  props: {
    id: String
  },
  data() {
    // 元数据
    const {
      titleItem,
      applyItem,
      basicItem,
      contentItem,
      totalItem,
      descriptionItem,
      stepsItem
    } = descriptors;
    const item = localStorage.getItem('user');
    const user = JSON.parse(item);
    const { name, jobnumber, mobile } = user;
    return {
      active: 0,
      key: 0,
      descriptor: {
        title: [titleItem()],
        apply: [applyItem()],
        basic: [basicItem()],
        content: [contentItem(false, 0)],
        total: [totalItem()],
        description: [descriptionItem()]
      },
      // 表单模型
      model: {
        title: [{ title: '', subcompary: '' }],
        apply: [{ applyDate: moment(), requireDate: moment() }],
        basic: [
          {
            apply: name,
            employee: jobnumber,
            phone: mobile,
            office: '',
            using: '',
            buy: [],
            catagory: '',
            project: '',
            outlay: '',
            cost: '',
            subject: [],
            append: '',
            appendId: ''
          }
        ],
        content: [
          {
            index: '',
            name: '',
            description: '',
            number: 0,
            price: 0,
            amount: 0,
            actualPrice: 0,
            actualAmount: 0
          }
        ],
        total: [
          {
            total: ''
          }
        ],
        description: [
          {
            reason: '',
            files: null
          }
        ]
      },
      steps: stepsItem(),
      loading: false
    };
  },
  computed: {
    hasId() {
      return this.id && this.id != '';
    },
    submitText() {
      if (this.hasId) {
        return '重新提交';
      } else {
        return '提交';
      }
    }
  },
  watch: {
    'model.content': {
      handler: function(newValue) {
        this.model.content.forEach(item => {
          const value = item.number * item.price;
          item.amount = value;
        });
        const total = newValue.reduce((a, b) => {
          return a + b.amount;
        }, 0);
        this.model.total[0].total = total.toFixed(2);
      },
      deep: true
    }
  },
  async created() {
    const { hasId, getItem } = this;
    if (hasId) {
      await getItem();
    }
  },
  methods: {
    async getItem() {
      const { model, descriptor, add, steps } = this;
      const data = await this.$http.get('/orders/detail', {
        params: { id: this.id }
      });
      model.title[0].title = data.title;
      model.title[0].subcompary = data.subcompary;
      model.basic = data.basic;
      model.basic[0].buy = data.basic[0].buy.split(',');
      model.basic[0].subject = data.basic[0].subject.split(',');
      model.content = [];
      descriptor.content = [];
      data.content.forEach(item => {
        add(item);
      });
      descriptor.content[0].closable = true;
      model.description = data.description;
      this.currentStep = data.steps.currentStep;
      steps.forEach((item, index) => {
        const users = data.steps[`step${index + 1}`];
        const length = users?.length;
        const detail = length ? `${length}人审批` : '0人审批';
        item.detail = detail;
        item.status = this.currentStep > index ? 'finish' : 'process';
        item.users = users;
      });
    },
    onEditTabs(key, action) {
      this[action](key);
    },
    add(item) {
      const { key, model, descriptor } = this;
      const { contentItem } = descriptors;
      this.key++;
      const pane = contentItem(true, key);
      descriptor.content.push(pane);
      if (!(item instanceof Event)) {
        model.content.push(item);
      } else {
        model.content.push({
          index: '',
          name: '',
          description: '',
          number: 0,
          price: 0,
          amount: 0,
          actualPrice: 0,
          actualAmount: 0
        });
      }
    },
    remove(key) {
      this.active = 0;
      this.descriptor.content.splice(key, 1);
      this.model.content.splice(key, 1);
    },
    onChangeStep(e) {
      const { index, users } = e;
      const newUsers = users.map(item => {
        const { avatar, name, emplId } = item;
        return {
          avatar,
          name,
          userId: emplId
        };
      });
      this.steps[index].users = newUsers;
      console.log(newUsers);
    },
    payload() {
      const { model, steps } = this;
      const data = _.cloneDeep(model);
      data.title = model.title[0].title;
      data.subcompary = model.title[0].subcompary;
      // 日期
      data.apply[0].applyDate = model.apply[0].applyDate.format('YYYY-MM-DD');
      data.apply[0].requireDate = model.apply[0].requireDate.format(
        'YYYY-MM-DD'
      );
      // 支出类别、费用科目 多选合并
      data.basic[0].buy = model.basic[0].buy.join(',');
      data.basic[0].subject = model.basic[0].subject.join(',');
      // 步骤 1-6
      const newSteps = {};
      for (let i = 0; i < steps.length; i++) {
        newSteps[`step${i + 1}`] = steps[i].users;
      }
      data.steps = newSteps;
      // 汇总
      data.total = model.total[0].total;
      return data;
    },
    async onSubmit() {
      const { $router, $refs, $http, payload, hasId } = this;
      try {
        this.loading = true;
        await $refs.form.validate();
        const data = payload();
        if (hasId) {
          await $http.post(
            '/orders/update',
            Object.assign(data, { id: this.id })
          );
        } else {
          await $http.post('/orders/create', data);
        }
        await $router.back();
      } catch (error) {
        console.error(error);
        if (!error) {
          await this.$notification.error({
            message: '错误',
            description: '请填写完整表单字段'
          });
        }
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.ant-layout {
  margin: 32px;
  background-color: #fff;

  .ant-form {
    padding: 24px;
    overflow: auto;
  }
}
</style>

import offices from './basics-offices';
import costs from './basics-costs';
import subjects from './basics-subjects';

export default () => {
  return {
    prop: 'basic',
    title: '基本信息',
    metas: [
      {
        type: 'text',
        label: '申请人',
        prop: 'apply',
        value: '',
        required: true,
        disabled: true
      },
      {
        type: 'text',
        label: '员工编号',
        prop: 'employee',
        value: '',
        required: true,
        disabled: true
      },
      {
        type: 'text',
        label: '电话',
        prop: 'phone',
        value: '',
        required: true,
        disabled: true
      },
      {
        type: 'select',
        label: '申请科室',
        prop: 'office',
        value: '',
        options: offices(),
        required: true
      },
      {
        type: 'select',
        label: '使用科室',
        prop: 'using',
        value: '',
        options: offices(),
        required: true
      },
      {
        type: 'select',
        label: '采购科室',
        prop: 'buy',
        value: '',
        mode: 'multiple',
        optoins: [
          { label: '综合管理科', value: '综合管理科' },
          { label: '自行采购', value: '自行采购' }
        ]
      },
      {
        type: 'select',
        label: '采购类别',
        prop: 'catagory',
        value: '',
        optoins: [
          { label: '项目性', value: '项目性' },
          { label: '非项目性', value: '非项目性' }
        ],
        required: true
      },
      {
        type: 'text',
        label: '项目名称',
        prop: 'project',
        value: ''
      },
      {
        type: 'select',
        label: '支出类别',
        prop: 'outlay',
        optoins: [
          { label: '资本性', value: '资本性' },
          { label: '费用性', value: '费用性' }
        ],
        required: true
      },
      {
        type: 'select',
        label: '成本中心',
        prop: 'cost',
        value: '',
        options: costs(),
        required: true
      },
      {
        type: 'select',
        label: '费用科目',
        prop: 'subject',
        value: '',
        mode: 'multiple',
        options: subjects(),
        required: true
      },
      {
        type: 'select',
        label: '是否增补单据',
        prop: 'append',
        value: '',
        options: [
          { label: '是', value: '是' },
          { label: '否', value: '否' }
        ]
      },
      {
        type: 'text',
        label: '原单据号',
        prop: 'appendId',
        value: ''
      }
    ]
  };
};
